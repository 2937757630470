<template>
  <section class="hero" >
    <Chats/>
  </section>
</template>

<script>

export default {
  name: 'ChatsView',
  components: {
    Chats: () => import('@/components/Chats')
  }
}
</script>

<style scoped>
  #page404 {
    background-color: #292A30;
  }
  .title, .subtitle, .link {
    color: #FFFFFF;
    margin-bottom: 20px;
  }
</style>
